import React, {useState} from 'react';
import {Box, Input} from '@material-ui/core';

const Login: React.FC = () => {
    const [oldUsers, setOldUsers] = useState([]);
    // useEffect(async () => {
    //     const users = await (new PdrDatabase()).users?.toArray();
    // });

    return (
        <>
            <Box>
                <Input placeholder={'Введите имя'}/>
            </Box>
            <Box>

            </Box>
        </>
    )
}
export default Login;
