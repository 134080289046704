import React from 'react';
import {Link} from 'react-router-dom';
import styles from './index.module.scss';
import {Home} from '@material-ui/icons';
import {AppBar, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography} from '@material-ui/core';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                display: 'none'
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        item: {
            '& a': {
                textDecoration: 'none',
                color: 'black'
            }
        }
    }),
);

const NavMenu: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const drawer = (
        <div>
            <List>
                <ListItem className={classes.item} onClick={()=>setMobileOpen(false)}>
                    <Link to="/"><Home fontSize="small"/></Link>
                </ListItem>
                <ListItem className={classes.item} onClick={()=>setMobileOpen(false)}>
                    <Link to="/test/reload">Тест билеты</Link>
                </ListItem>
                <ListItem className={classes.item} onClick={()=>setMobileOpen(false)}>
                    <Link to={`/testsections/reload`}>Тест разделы</Link>
                </ListItem>
                <ListItem className={classes.item} onClick={()=>setMobileOpen(false)}>
                    <Link to="/myfailed">Работа над ошибками</Link>
                </ListItem>
                <ListItem className={classes.item} onClick={()=>setMobileOpen(false)}>
                    <Link to="/find">Поиск</Link>
                </ListItem>
            </List>
        </div>
    );
    return (
        <>
            <AppBar position="relative" className={classes.appBar}>
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </>
       /* <div className={styles.navmenu}>
            <div className={styles.item}>
                <Link to="/"><Home fontSize="small"/></Link>
            </div>
            <div className={styles.item}>
                <Link to="/test/reload">Тест билеты</Link>
            </div>
            <div className={styles.item}>
                <Link to={`/testsections/reload`}>Тест разделы</Link>
            </div>
            <div className={styles.item}>
                <Link to="/find">Поиск</Link>
            </div>
        </div>*/
    );
}
export default NavMenu;
