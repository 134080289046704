import React, {PureComponent} from 'react';
import './App.scss';

import {Switch, Route, Redirect} from 'react-router';
import Test from './pages/test';

import * as serviceWorker from './serviceWorkerRegistration';
import NavMenu from './components/navmenu';
import {userContext} from './contexts/userContext';
import Login from './pages/login';
import {db} from './db';
import {FindPage} from './pages/find';
import {makeStyles} from '@material-ui/core';
import {createStyles, Theme} from '@material-ui/core/styles';
import {Layout} from './Layout';
import {MyfailedPage} from './pages/myfailed';
import {DashboardPage} from './pages/dashboard';


interface AppState {
    hasUpdate: boolean,
    user: {} | undefined
}



class App extends PureComponent<{}, AppState> {

    protected registration: ServiceWorkerRegistration | null;

    constructor(props: {}) {
        super(props);
        this.registration = null;
        this.state = {
            hasUpdate: false,
            user: undefined,
        }
        const lastUser = localStorage.getItem('lastUser');
        if (db.users !== undefined && lastUser) {
            db.users.where('name').equals(lastUser).first().then((res) => {
                this.setState({
                    // user: res ? {[res.id]: ''} : {}
                })
            });
        } else {
            this.setState({
                user: {}
            })
        }
        // db.users.where('name').equal
    }

    componentDidMount(): void {
        console.log('componentDidMount');
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.register({
            onSuccess: (registration) => {
                console.debug('onSuccess');
                debugger;
            },
            onUpdate: (registration) => {
                console.debug('onUpdate');
                this.registration = registration;
                this.setState({
                    hasUpdate: true
                })
            },
        });
    }

    public render() {
        return (
            <Layout>
                <userContext.Provider value={this.state.user}>
                    <Switch>
                        {/*<Route exact path='/test' component={Test}/>*/}
                        <Redirect from={'/test/reload'} to={'/test'}/>
                        <Redirect from={'/testsections/reload'} to={'/testsections'}/>
                        <Route exact path="/test" component={(props: any) => (
                            <Test timestamp={new Date().toString()} {...props} mode={'exam'}/>
                        )}/>
                        <Route exact path="/testsections" component={(props: any) => (
                            <Test timestamp={new Date().toString()} {...props} mode={'sections'}/>
                        )}/>
                        <Route exact path="/testfailed" component={(props: any) => (
                            <Test timestamp={new Date().toString()} {...props} mode={'failed'}/>
                        )}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/find/:questionId?" component={FindPage}/>
                        <Route exact path="/myfailed" component={MyfailedPage}/>
                        <Route exact path="/" component={(props: any) => (
                            <DashboardPage {...props} />
                        )}/>
                    </Switch>
                </userContext.Provider>
                {this.state.hasUpdate && <div onClick={this.updateIt.bind(this)} className="update-handler">Есть обновление - обновить?</div>}
            </Layout>
        );
    }

    private async updateIt() {
        if (this.registration !== null) {
            await this.registration.unregister();
            window.location.reload();
            // @ts-ignore
            // this.registration.waiting.postMessage({type: 'SKIP_WAITING'});
        }
    }
}

export default App;
