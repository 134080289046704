import {Box, Dialog, DialogTitle, List, ListItem, ListItemText, makeStyles, Typography, withStyles} from '@material-ui/core';
import React from 'react';
import {DateTime} from 'luxon';

const styles = makeStyles((theme) => ({
    container: {
        width: '100px'
    }
}));

interface IResultProps {
    results: { [key: string]: boolean },
    time: number,
    onClose: () => void
}

export const Result: React.FC<IResultProps> = ({results, time, onClose}) => {
    const keys = Object.keys(results);
    const classes = styles();
    const successAns = keys.filter(k => results[k]);
    const failedAns = keys.filter(k => !results[k]);
    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={true}>
            <DialogTitle id="simple-dialog-title">результат</DialogTitle>
            <List>
                <ListItem>
                    <Typography>Успешно:&nbsp;</Typography>
                    <Typography color="primary">{successAns.length}</Typography>
                </ListItem>
                <ListItem>
                    <Typography>Ошибок:&nbsp;</Typography>
                    <Typography color="secondary">{failedAns.length}</Typography>
                </ListItem>
                <ListItem>
                    <Typography>Потраченное время:&nbsp;</Typography>
                    <Typography>{DateTime.fromSeconds(time + 1).toFormat('mm:ss')}</Typography>
                </ListItem>
            </List>
        </Dialog>
    )
}
