import React, {useEffect, useState} from 'react';
import {db, getFailedQuestions} from '../../db';

export const MyfailedPage: React.FC = () => {
    const [exams, setExams] = useState(0);
    useEffect(() => {
        (async () => {
            const failedQuestion = await getFailedQuestions();
            console.log(failedQuestion);
        })();
    }, []);
    return (
        <>
        </>
    );
}
