import {Box, Container, makeStyles, TextField} from '@material-ui/core';
import React, {useCallback, useMemo, useState} from 'react';
import questionsData from '../../data/ticketsv3.json';
import {Questions} from '../../components/questions';
import {IQuestion} from '../../components/question';
import {RouteComponentProps, RouterProps} from 'react-router';
import {BrowserRouterProps} from 'react-router-dom';
import {getFindItems, getItemByQuestion} from '../../data';

const useStyles = makeStyles(theme => ({}));
interface IFindProps extends RouteComponentProps{}
export const FindPage: React.FC<IFindProps> = ({match}) => {
    const classes = useStyles();
    const questionId = (match.params as any).questionId;
    const [filter, setFilter] = useState('');

    const getItems = (): IQuestion[] => {
        if(questionId && !filter){
            return getItemByQuestion(questionId);
        }
        if (!filter) return [];
        return  getFindItems(filter);
    }
    const items = useMemo<IQuestion[]>(() => getItems(), [filter, questionId]);

    const sectionChangeHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFilter(event.target.value as string);
    };

    return (
        <Container maxWidth="xl">
            <Box>
                <TextField id="standard-basic" label="Найти билеты" onChange={sectionChangeHandler}/>
            </Box>
            <Questions items={items}/>
        </Container>
    );
}
