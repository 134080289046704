import React, {FC, useMemo, useState} from 'react';

import {
    Box,
    Button,
    ButtonBase,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel, TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import sections from '../../data/sections.json';
import {Link} from 'react-router-dom';
import {useLocalStorage, writeStorage} from '@rehooks/local-storage';
import * as R from 'ramda';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {IQuestion} from '../question';
import {getFindItems, getItemByQuestion} from '../../data';

interface ISelectSectionsProps {
    selectSection: (sections: {}) => void,
    selected: any,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column'
        },
    }));

export const SelectSections: FC<ISelectSectionsProps> = ({selectSection, selected}) => {
    const classes = useStyles();
    // const [lastTempSections] = useLocalStorage<{ [key: string]: boolean }>('lastTempSections');

    const [tempSection, setTempSection] = useState<{ [key: string]: boolean }>(selected ? selected : {});

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const getSelectedSections = (result: any) => {
        const isTrue = (val: any, key: any) => val;
        return R.pickBy(isTrue, result);
    }

    const sectionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        // debugger;
        const result: any = getSelectedSections({...tempSection, [event.target.name]: event.target.checked});
        // writeStorage('lastTempSections', result);
        setTempSection(result);
    };

    const [filter, setFilter] = useState('');

    const sectionFindHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFilter(event.target.value as string);
    };
    const getItems = (): string[] => {
       return sections.filter(f=>!filter || f.toLowerCase().includes(filter.toLowerCase()));
    }
    const items = useMemo<string[]>(() => getItems(), [filter]);

    // const items = getItemsBySection(Object.keys(getSelectedSections(tempSection)));

    return (
        <Dialog
            open={true}
            fullScreen={fullScreen}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">Выбрать разделы</DialogTitle>
            <DialogContent>
                <Box>
                    <TextField id="standard-basic" label="Фильтр" onChange={sectionFindHandler}/>
                </Box>
                <DialogContentText className={classes.content}>
                    {items.map((m,i) =>
                        <FormControlLabel
                            style={{paddingTop: '10px'}}
                            key={m}
                            labelPlacement="end"
                            control={
                                <Checkbox
                                    checked={!!tempSection[m]}
                                    onChange={sectionChangeHandler}
                                    name={m}
                                    color="primary"
                                />
                            }
                            label={(i+1)+'. '+m}
                        />
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between'}}>
                <Typography>
                    Выбрано {Object.keys(tempSection).length}
                </Typography>
                <Box display="flex" justifyItems="space-around">
                    <ButtonBase
                        component={Link}
                        to={'/'}
                    >
                        Отмена
                    </ButtonBase>
                    {/*<Link to="/" button>Отмена</Link>*/}
                    <Button autoFocus onClick={()=>selectSection(tempSection)} color="primary">
                        Начать
                    </Button>
                </Box>

            </DialogActions>
        </Dialog>
    )
}
