import React, {useEffect, useState} from 'react';
import {db, getFailedQuestions} from '../../db';

export const DashboardPage: React.FC = () => {
    const [exams, setExams] = useState(0);
    useEffect(() => {
        (async () => {
            const examsCount = await db.exams?.count();
            // @ts-ignore
            setExams(examsCount);

            const failedQuestion = await getFailedQuestions();
            console.log(failedQuestion);
        })();
    }, []);

    return (
        <>
            Пройдено тестов: {exams}
        </>
    );
}
