import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {DateTime} from 'luxon';

interface ITimerProps {
    baseDate: DateTime,
    mode: boolean,
    state: boolean,
    maxTicks: number,
    onTick?: (total:number)=>void
}

const Timer: React.FC<ITimerProps> = ({mode, baseDate, state, maxTicks, onTick}) => {
    const [currentTimer, setCurrentTimer] = useState(baseDate);

    const iterClock = () => {
        // debugger;
        const diff = Math.abs(baseDate.diff(currentTimer, 'seconds').seconds);
        // console.log('iterClock', state, diff, baseDate, currentTimer,  maxTicks);

        if (state && diff < maxTicks) {

            const a = (mode ? currentTimer.plus({seconds: 1}) : currentTimer.minus({seconds: 1}));
            if (onTick) {
                onTick(diff);
            }
            setCurrentTimer(a);
        }
    }

    useEffect(() => {
        // console.log(state);
        if (state) {
            const timer = setInterval(iterClock, 1000);
            // Clear timeout if the component is unmounted
            // console.log('setInterval');
            return () => {
                // console.log('clearTimeout');
                clearTimeout(timer);
            }
        }
    });
    const timerText = currentTimer.toFormat('mm:ss');
    return (
        <>
            {timerText}
        </>
    );
}
export default Timer;
