import styles from './index.module.scss';
import React, {useEffect, useMemo, useState} from 'react';
import Question, {IAnswer, IQuestion} from '../question';
import {DateTime} from 'luxon';

const randQuestions = (items: IQuestion[]) => {
    return items.slice().sort(() => Math.random() - 0.5);
}

interface IQuestionsProps {
    items: IQuestion[],
    onAnswer?: (i: string, result: IAnswer, ans: boolean, finished: boolean) => void,
    testDate?: DateTime
}

export const Questions: React.FC<IQuestionsProps> = ({items, onAnswer: onQuestionAnswer, testDate}) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState<any>({});

    const refs: any = {};

    const onAnswer = (i: string, result: IAnswer, ans: boolean) => {
        setAnswers({
            ...answers,
            [i]: ans
        },)
        const finded = examItems.find(f => f.question_id === i);
        if (finded) {
            finded.result = result;
        }
        nextItem();
        if (onQuestionAnswer) {
            onQuestionAnswer(i, result, ans, getNextItem() === -1);
        }
    }
    const getNextItem = () => {
        return examItems.findIndex(f => f.result === undefined);
    }
    const nextItem = () => {
        const index = getNextItem();
        if (index > -1) {
            setCurrentQuestion(index);
            scrollToItem(index);
        }
    }
    const scrollToItem = (i: number) => {
        // debugger;
        refs[i].current.scrollIntoView({
            behavior: 'smooth',
            alignToTop: true,
            // block: 'end',
        });
    }

    useEffect(() => {
        setAnswers({});
    }, [items]);


    const onClick = (m: number) => {
        setCurrentQuestion(m);
    }

    const examItems = useMemo(() => randQuestions(items), [items]);

    return (
        <div className={styles.questionsContainer}>
            <div className={styles.headerContainer}>
                {
                    examItems.map(
                        (m: IQuestion, i) => {
                            const ref = React.createRef<any>();
                            refs[i] = ref;
                            return (<div
                                ref={ref}
                                className={styles.blockItem +
                                (currentQuestion === i ? ' ' + styles.blockItem__current : '') +
                                (answers[m.question_id] === true ? ' ' + styles.blockItem__success : answers[m.question_id] === false ? ' ' + styles.blockItem__failed : '')}
                                key={'k' + i} onClick={() => onClick(i)}>{i + 1}</div>)
                        }
                    )
                }
            </div>
            {examItems.map((m, i) =>
                currentQuestion === i && <Question item={m} answerResult={answers[m.question_id]} onAnswer={onAnswer} key={'q' + i}/>)}
        </div>
    )
}

