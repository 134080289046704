// import questionsData from '../../data/ticketsv3.json';
// import sections from '../../data/sections.json';
import {Questions} from '../../components/questions';
import {IAnswer, IQuestion} from '../../components/question';
import React, {useEffect, useMemo, useState} from 'react';
import styles from './index.module.scss';
import {DateTime} from 'luxon';
import Timer from '../../components/timer';
import {Home, Pause, PlayArrow, Refresh} from '@material-ui/icons'
import {Redirect, useHistory} from 'react-router';
import {Result} from '../../components/result';
import {db} from '../../db';
import * as R from 'ramda'
import {
    Box,
    Button, ButtonBase, Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControlLabel,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import {useLocalStorage, writeStorage} from '@rehooks/local-storage';
import {Link} from 'react-router-dom';
import {SelectSections} from '../../components/selectSections';
import {getExamItems, getQuestionsBySection} from '../../data';
import useRefresh from '../../helpers/useRefresh';

interface ITestProps {
    timestamp: string;
    mode: string;
}

const Test: React.FC<ITestProps> = ({timestamp, mode}) => {
    const [activeProcess, setActiveProcess] = useState(true);
    const [results, setResults] = useState<{ [key: string]: boolean }>({});
    const [finished, setFinished] = useState(false);
    const [totalTime, setTotalTime] = useState(0);
    const [examItems, setExamItems] = useState<IQuestion[]>([]);
    const [section, setSection] = useState<string[]>([]);
    const [examSection, setExamSection] = useState<string[]>([]);
    const [maxTicks, setMaxTicks] = useState(0);
    const history = useHistory();

    const [lastExamSections] = useLocalStorage<{ [key: string]: boolean }>('lastExamSections');
    const [lastSections] = useLocalStorage<{ [key: string]: boolean }>('lastSections');


    const refresh = useRefresh(history, window.location.pathname);

    const start = DateTime.fromSeconds(0);
    const restart = () => {
        refresh();
    }
    const onClose = () => {
        setFinished(false);
    }

    const saveResults = async () => {
        const newId = await db.exams?.add({examDate: DateTime.local(), userId: 1, addInfo: section && section.length ? JSON.stringify(section) : ''});
        if (newId) {
            for (const question_id in results) {
                db.examItems?.add({questionId: question_id, userId: 1, examId: newId, result: results[question_id] ? 1 : 0})
            }
        }
    }

    useEffect(() => {
        if (mode === 'exam' && examSection.length) {
            const items =  getQuestionsBySection(examSection, 20);
            setMaxTicks(60 * 20);
            setExamItems(items);
        }
        if (mode === 'sections' && section.length) {
            const items = getQuestionsBySection(section,1000);
            setMaxTicks(60 * items.length);
            setExamItems(items);
        }
    }, [section, examSection, timestamp]);

    useEffect(() => {
        if (finished) {
            saveResults();
        }
    }, [finished]);

    const onAnswer = (i: string, result: IAnswer, ans: boolean, finished: boolean) => {
        if (!activeProcess) {
            setActiveProcess(true);
        }
        setResults({
            ...results,
            [i]: ans
        })

        if (finished) {
            setActiveProcess(false)
            setFinished(true);
        }

    }

    const startSectionsTest = (selected: {}) => {
        // debugger;
        writeStorage('lastSections', selected);
        setSection(Object.keys(selected));
    }
    const startExamTest = (selected: {}) => {
        // debugger;
        writeStorage('lastExamSections', selected);
        setExamSection(Object.keys(selected));
    }

    const getSelectedSections = (result: any) => {
        const isTrue = (val: any, key: any) => val;
        return R.pickBy(isTrue, result);
    }

   /* const sectionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        // debugger;
        const result: any = getSelectedSections({...tempSection, [event.target.name]: event.target.checked});
        writeStorage('lastTempSections', result);
        setTempSection(result);
    };*/


    if (mode === 'sections' && !section.length) {
        // @ts-ignore
        // const items = getItemsBySection(Object.keys(getSelectedSections(tempSection)));

        return (
           <SelectSections selectSection={startSectionsTest} selected={lastSections}/>
        );
    }
    if (mode === 'exam' && !examSection.length) {
        return (
           <SelectSections selectSection={startExamTest} selected={lastExamSections}/>
        );
    }
    return (
        <>
            <div className={styles.header}>
                <div className={styles.timerWrapper}>Прошло: <Timer baseDate={start} mode={true} state={activeProcess} maxTicks={maxTicks} onTick={(t) => setTotalTime(t)}/>
                </div>
                <div className={styles.actionsWrapper}>
                    {activeProcess && <Pause onClick={() => setActiveProcess(!activeProcess)}/>}
                    {!activeProcess && <PlayArrow onClick={() => setActiveProcess(!activeProcess)}/>}
                    {<Refresh onClick={() => restart()}/>}
                </div>

                {maxTicks && <div className={styles.timerWrapper}>Осталось: <Timer baseDate={start.plus({seconds: maxTicks})} mode={false} state={activeProcess} maxTicks={maxTicks}/></div>}
            </div>
            {/*{mode === 'sections' && section.length && <Typography variant="h4">{section}</Typography>}*/}
            <Questions items={examItems} onAnswer={onAnswer} testDate={start}/>
            {/*{redirectIt && <Redirect to="/test/reload"/>}*/}
            {finished && <Result results={results} time={totalTime} onClose={onClose}/>}
        </>
    );
}


export default Test;
