import Dexie from 'dexie';
import {DateTime} from 'luxon';


export interface IUser {
    id?: number;
    name: string
}

export interface IExam {
    id?: number,
    examDate: DateTime,
    userId: number,
    addInfo?: string
}

export interface IExamItem {
    id?: number,
    examId: number,
    questionId: string,
    userId: number,
    result: number
}

class PdrDatabase extends Dexie {
    public users: Dexie.Table<IUser, number> | undefined;
    public exams?: Dexie.Table<IExam, number>;
    public examItems?: Dexie.Table<IExamItem, number>;

    constructor() {
        super('pdr');
        this.version(1).stores({
            users: '++id,name',
            exams: '++id,examDate,userId,addIinfo',
            examItems: '++id,examId,questionId,userId,result',
        });
    }
}

export const db = new PdrDatabase();

export const getFailedQuestions = async () => {
    // @ts-ignore
    return await db.examItems?.where('result').equals(0).toArray();
}

