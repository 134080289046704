import * as React from 'react';
import {Container, CssBaseline, makeStyles} from '@material-ui/core';
import {createStyles, Theme} from '@material-ui/core/styles';
import NavMenu from './components/navmenu';
import {userContext} from './contexts/userContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        drawer: {
            width: 240,
            flexShrink: 0,
        },
        drawerPaper: {
            width: 240,
        },
        drawerContainer: {
            overflow: 'auto',
        },
        content: {
            flexGrow: 1,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: "240px"
            },
            // padding: theme.spacing(3),
        },
    }),
);
export const Layout: React.FC = (props: any) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline/>
            <NavMenu/>
            <Container className={classes.content}>
                {props.children}
            </Container>
        </React.Fragment>)
}

