import {IQuestion} from '../components/question';

import questionsData from './ticketsv3.json';
import sectionsData from './sections.json';
import sectionsBData from './sections_b.json';

export const getQuestionsBySection = (sections: string[], limit = 20, random = false) => {
    const items = (questionsData as IQuestion[]).filter(f => ~sections.indexOf(f.section)).reduce((acc: any[], value) => acc.some(i => i.question_id === value.question_id) ? acc : acc.concat(value), []).slice().sort(() => Math.random() - 0.5).slice(0, limit);
    return items;
}

export const getExamItems = (count: number = 20) => {
    const examItems:any = [];
    while (true) {
        const section = sectionsBData.slice().sort(() => Math.random() - 0.5).slice(0, 1);
        const item = getQuestionsBySection(section, 1)[0];
        if(examItems.indexOf(item) !== -1){
            continue;
        }
        examItems.push(item);
        if(examItems.length === count){
            break;
        }
    }
    return examItems;
}

export const getFindItems = (filter:string)=>{
    return (questionsData as IQuestion[]).filter(f =>
        f.text?.toLowerCase().includes(filter.toLowerCase())
        || f.reply_ticket?.toLowerCase().includes(filter.toLowerCase())
        || f.title?.toLowerCase().includes(filter.toLowerCase())
    ).reduce((acc:any[], value) => acc.some(i => i.question_id === value.question_id) ? acc : acc.concat(value), [])
}
export const getItemByQuestion = (question: string)=>{
    return (questionsData as IQuestion[]).filter(f =>
        f.question_id === question
        || f.question_number?.toLowerCase() === question.toLowerCase() );
}

