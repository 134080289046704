import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import placeholder from './images/no-image-placeholder.svg';
import {Favorite, FavoriteBorder, FavoriteBorderOutlined, FavoriteBorderRounded, FavoriteBorderSharp, FavoriteBorderTwoTone} from '@material-ui/icons';
import {useLocalStorage, writeStorage} from '@rehooks/local-storage';

export interface IAnswer {
    answerText: string,
    id: string,
    question_index: string,
    is_true: boolean
}

export interface IQuestion {
    ticket_number?: string,
    question_id: string,
    question_number?: string,
    title?: string,
    text?: string
    reply_ticket?: string,
    section: string,
    ticket_left?: string | null,
    answers: IAnswer[],
    result?: IAnswer
}

interface IQuestionProps {
    item: IQuestion,
    answerResult?: boolean,
    onAnswer: (i: string, result: IAnswer, ans: boolean) => void
}


export const Question: React.FC<IQuestionProps> = ({item, onAnswer, answerResult}) => {
    const {ticket_left, reply_ticket, title, result, question_id, text, answers, section} = item;
    const trueAnswer = answers.find(f => f.is_true);

    // const [storageFavValue] = useLocalStorage<boolean>('fav'+question_id);
    const [storageFavValue, setStorageFavValue] = useLocalStorage<boolean>('fav'+question_id);
    const [favValue, setFavValue] = useState(storageFavValue);

    const [selectedResult, setSelectedResult] = useState<IAnswer | null | undefined>(result);
    const [showPrompt, setShowPrompt] = useState(selectedResult && selectedResult !== trueAnswer);

    const fixImages = (text: string | null | undefined): string => {
        if (text) {
            text = text.replace(/src="/gm,'src="https://vodiy.ua');
            text = text.replace(/href="/gm,'href="https://vodiy.ua');
            return text;
        }
        return '';
    }
    const parsed_ticket_left = fixImages(ticket_left);
    const parsed_reply_ticket = fixImages(reply_ticket);

    const onSelectAnswer = (ans: IAnswer) => {
        if (selectedResult === undefined) {
            setSelectedResult(ans);
            onAnswer(question_id, ans, ans === trueAnswer)
        }
    }

    const toggleFav = (val: boolean) => {
        // debugger;
        // writeStorage('fav' + question_id, !favValue);
        setStorageFavValue(!favValue);
        setFavValue(!favValue);
    }

    return (
        <div className={styles.container}>
            <div className={styles.ticketName}>{title}{favValue ? <Favorite onClick={() => toggleFav(false)}/> : <FavoriteBorder onClick={() => toggleFav(true)}/>}</div>
            <div className={styles.ticketName}>{section}</div>
            <div className={styles.wrapper}>
                {parsed_ticket_left && <div className={styles.mediaText} dangerouslySetInnerHTML={{__html: parsed_ticket_left || ''}}/>}
                {!parsed_ticket_left && <div className={styles.mediaText}><img src={placeholder}/></div>}
                <div className={styles.text}>{text}</div>
            </div>
            <div className={styles.answersWrapper + (showPrompt?" "+styles.answersWrapper__reply:'')}>
                {
                    answers.map((m, i) =>
                        <div
                            key={'ans' + m.id} onClick={() => onSelectAnswer(m)}
                            className={
                                styles.answerItem +
                                (
                                    selectedResult !== undefined && m === trueAnswer ? ' ' + styles.answerItem__success :
                                        selectedResult !== undefined && selectedResult !== trueAnswer && selectedResult === m ? ' ' + styles.answerItem__failed : ''
                                )
                            }
                        >{i + 1}. {m.answerText}</div>
                    )
                }
            </div>
            {!showPrompt && parsed_reply_ticket && <div className={styles.prompt} onClick={() => setShowPrompt(true)}>Пояснение</div>}
            {showPrompt && parsed_reply_ticket && <div className={styles.promptText} dangerouslySetInnerHTML={{__html: parsed_reply_ticket || ''}}/>}
        </div>
    );
}

export default Question;
